body{
    background: radial-gradient(#e5e5e5,#ffff,#e5e5e5);
    
}
.card{
    width: 23rem;
    margin: 3rem 1.5rem 3rem 0.5rem;
    height:30rem ;
    
}
.card:hover{
    box-shadow: 5px 10px 20px 1px rgba(0,0,0,0.253) !important;
}
.card-body{
    padding: 2rem 2rem !important;
}
 

.card-text{
    font-size: 0.9rem;
    padding: 0.4rem 1.9rem;
}


.container-fluid.row{
    padding-top: 4rem;
    
}
.overflow{
    overflow: hidden;
}
.card-img-top{
    height: 16rem;
    transform: scale(1);
    transition:transform 0.5s ease ;
   
}
.card-img-top:hover{
    
    transform: scale(1.1);
}

@media(max-width:820px){
    .card{
    width: 22rem;
    margin: 3rem 1.5rem 3rem -0.25rem;
    height:28rem ;
    }
}

.servicemore{
    margin-top: 2rem !important;
}