

.Navbar{
    position: fixed !important;
}
.navbar-container .container{
    position: fixed;

}


.navbar-light .navbar-nav .nav-link{
    color: white;
  }
  
  .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link {
    color: white !important;
  }
  .navbar-light .navbar-toggler{
    color: white;
  }
  
  .navbarlogo{
        height:20% !important;
        width: 20% !important;
        margin: 0rem;
        
    }
  
    a.nav-link:hover{
        color: grey;

    }
    .ms-auto .nav-link{
      padding-left: 2rem !important;
    }
    
    
  