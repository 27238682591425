body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html,body{
  overflow-x: hidden;
}

@media (max-width:820px)
{
  .home__hero-img{
    width: 100% !important;
  }
}

p{
  font-size: 1.5rem !important;

}
.app{

    overflow-x:none;
}
.section{
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}
.main-heading{
    color: rgb(204,18,18);
    font-size: 1.5rem !important;
}
.underline{
    height: 04px;
    width: 4rem;
    background-color: #f76c2f;
    margin-top: 10px;
    margin-bottom: 10px;
}
.bg-c-light{
    background-color: #f2f2f2;
}
.serviceheading{
    text-align: center;
    color: rgb(204,18,18);
    font-size: 1.5rem;
}
.servicemore{
    text-align: center;
}
.contactheading{
    text-align: center;
    color: rgb(204,18,18);
    font-size: 1.5rem;
}
.aboutheading{
    text-align: center;
    color: rgb(204,18,18);
    font-size: 1.5rem;
}

 img{
    height: 40rem;
    max-width: 70%;
    margin: 2%;
    margin-left: 0rem;
}

Carousel.Caption{
    padding-top: 10rem;
}

.carousel-item{
    margin: 5%;
}
.homeheading{
    color: rgb(204,18,18);


}
.homeparagraph{
 color: white;
 background-color: black;
 margin-bottom: 4rem;
 
}


.Navbar{
    position: fixed !important;
}
.navbar-container .container{
    position: fixed;

}


.navbar-light .navbar-nav .nav-link{
    color: white;
  }
  
  .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link {
    color: white !important;
  }
  .navbar-light .navbar-toggler{
    color: white;
  }
  
  .navbarlogo{
        height:20% !important;
        width: 20% !important;
        margin: 0rem;
        
    }
  
    a.nav-link:hover{
        color: grey;

    }
    .ms-auto .nav-link{
      padding-left: 2rem !important;
    }
    
    
  
body{
    background: radial-gradient(#e5e5e5,#ffff,#e5e5e5);
    
}
.card{
    width: 23rem;
    margin: 3rem 1.5rem 3rem 0.5rem;
    height:30rem ;
    
}
.card:hover{
    box-shadow: 5px 10px 20px 1px rgba(0,0,0,0.253) !important;
}
.card-body{
    padding: 2rem 2rem !important;
}
 

.card-text{
    font-size: 0.9rem;
    padding: 0.4rem 1.9rem;
}


.container-fluid.row{
    padding-top: 4rem;
    
}
.overflow{
    overflow: hidden;
}
.card-img-top{
    height: 16rem;
    -webkit-transform: scale(1);
            transform: scale(1);
    transition:-webkit-transform 0.5s ease;
    transition:transform 0.5s ease ;
    transition:transform 0.5s ease, -webkit-transform 0.5s ease;
   
}
.card-img-top:hover{
    
    -webkit-transform: scale(1.1);
    
            transform: scale(1.1);
}

@media(max-width:820px){
    .card{
    width: 22rem;
    margin: 3rem 1.5rem 3rem -0.25rem;
    height:28rem ;
    }
}

.servicemore{
    margin-top: 2rem !important;
}
.whatsapp_float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;

    z-index: 100;
}

.whatsapp-icon {
    margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
    .whatsapp-icon {
        margin-top: 10px;
    }

    .whatsapp_float {
        width: 40px;
        height: 40px;
        bottom: 20px;
        right: 10px;
        font-size: 22px;
    }
}

  
  
.home__hero-section {
    color: #fff;
    padding: 160px 0;
  }
  
  .home__hero-row {
    align-items: center;
  }
  
  .row {
    display: flex;
    margin-right: -15px;
    margin-bottom: -15px;
    margin-left: -15px;
    flex-wrap: wrap;
    align-content: stretch;
  }
  
  .col {
    margin-bottom: 15px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 1;
    max-width: 50%;
    flex-basis: 50%;
  }
  
  .home__hero-text-wrapper {
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 60px;
  }
  
  .top-line {
    color: rgb(204,18,18);
    font-style: oblique;
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }
  
  .heading {
    margin-bottom: 1.5rem;
    font-size: 2.5rem;
    line-height: 1.1;
    font-weight: 600;
    color: #f7f8fa;
    
  }
  
  .dark {
    color: #1c2237;
  }
  
  .darkBg {
    background-color: #1c2237;
  }
  
  .home__hero-subtitle {
    max-width: 22rem;
    margin-bottom: 2.2rem;
    font-size: 1.5rem;
    line-height: 1.6rem;
  }
  
  .home__hero-img-wrapper {
    max-width: 30rem;
  }
  
  .home__hero-img {
    max-width: 90%;
    margin-top: 0;
    margin-right: 0;
    margin-left: 10px;
    padding-right: 0;
    max-height: -webkit-fit-content !important;
    max-height: -moz-fit-content !important;
    max-height: fit-content !important;
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: -webkit-transform 0.5s ease;
    transition: transform 0.5s ease;
    transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  }
  .home__hero-img:hover{-webkit-transform: scale(1.1);transform: scale(1.1);}
  
  
  img {
    border: 0;
    max-width: 90%;
    vertical-align: middle;
    display: inline-block;
  }
  
  @media screen and (max-width: 992px) {
    .container {
      padding-right: 30px;
      padding-left: 30px;
      
    }
    .col {
      max-width: 100%;
      flex-basis: 100%;
    }
  }
  
  @media screen and (max-width: 768px) {
    .home__hero-text-wrapper {
      padding-bottom: 65px;
    }
  
    .col {
      max-width: 100%;
      flex-basis: 100%;
    }
    
  }
 
 
