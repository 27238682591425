.app{

    overflow-x:none;
}
.section{
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}
.main-heading{
    color: rgb(204,18,18);
    font-size: 1.5rem !important;
}
.underline{
    height: 04px;
    width: 4rem;
    background-color: #f76c2f;
    margin-top: 10px;
    margin-bottom: 10px;
}
.bg-c-light{
    background-color: #f2f2f2;
}
.serviceheading{
    text-align: center;
    color: rgb(204,18,18);
    font-size: 1.5rem;
}
.servicemore{
    text-align: center;
}
.contactheading{
    text-align: center;
    color: rgb(204,18,18);
    font-size: 1.5rem;
}
.aboutheading{
    text-align: center;
    color: rgb(204,18,18);
    font-size: 1.5rem;
}