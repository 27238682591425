
 img{
    height: 40rem;
    max-width: 70%;
    margin: 2%;
    margin-left: 0rem;
}

Carousel.Caption{
    padding-top: 10rem;
}

.carousel-item{
    margin: 5%;
}
.homeheading{
    color: rgb(204,18,18);


}
.homeparagraph{
 color: white;
 background-color: black;
 margin-bottom: 4rem;
 
}